/* Courses */

/* CoursesList */

/* CoursesDetails */
const courseContent =
    [
        {
            title: 'Master Class VIP Microblading Avanzado',
            subtitle1: 'Natural Line Trazos Hiperrealistas',
            subtitle2: "Presencial Grupal / Personalizado",
            photo: 'images/microblanding-1.JPEG',
            photos: ['images/microblanding-1.JPEG'],
            description:[ "Aprenderás con nosotros la técnica más novedosa del mundo, al realizar trazos manuales simulando vellos naturales a través de la implantación de pigmentos."],
            techniques: [
                {
                    name: 'Microblading básico & avanzado',
                    description: 'Trazos manuales simulando vellos naturales a través de la implantación de pigmentos.'
                },
                {
                    name: 'Natural Line',
                    description: 'Realizando conexiones y trazos libres adaptadas a la necesidad. Es la creación de cejas únicas a su máxima expresión, elegancia y naturalidad.'
                },
                // {
                //     name: 'Powder Brows',
                //     description: 'Efecto polvo, obteniendo menos intensidad en la zona de la cabeza y mayor potencia de color en el final de la ceja.'
                // },
                // {
                //     name: 'Hibrida',
                //     description: 'Combinación de dos técnicas Microblading y el Powder brows. El primero busca más el realismo y con el segundo complementa profundidad, saturación y definición al resultado.'
                // },
                // {
                //     name: 'Compacto',
                //     description: 'Una técnica de mayor realce y definición al contorno de las cejas, también es una alternativa para realizar en procedimiento anterior como tatuajes o micropigmentación Te enseño a clasificar en diseño, color y técnica apropiada para esos casos.'
                // },
            ],
            content: ['Introducción en microblading', "Visagismo, diseño de cejas y clasificación de espinas", "Conocimiento de la piel", "Profundidad y presición.", "Indicaciones y contraindicaciones",
                "Historía clínica", "Bioseguridad", "Anestesia", "Cuidados post tratamiento", "Protocolo completo para realizar el procedimiento de cejas desde el inicio hasta el final",
                "Cómo realizar un retoque", "Manejo de equipos", "Técnicas de trazos pelo a pelo, avanzadas", "Técnica Natural Line", "Práctica en piel sintética", "Práctica en modelo real en compañía del máster",
                "Colorimetría", "Manejo de la fotografía y redes sociales", "Proveedores"],
            kits: [
                ["1 Inductor", "10 Agujas microblading", "1 Dermógrago", "10 Agujas para dermógrafo", "1 Kit de 3 pigmentos", "2 Piel Sintética", 
                "1 Regla calibradora pie de Rey", "1 Regla compás","1 Lápiz de cera", "1 Anestesia en crema", "Cartilla de aprendizaje", "Bloc de notas", "Certificado",
                "Acompañamiento durante 6 meses vía WhatsApp"],
                ["Delantal", "Protector para el cabello",]
            ],
            days: [

                ['Introducción al microblading.', 'Visagismo', 'Diseño de cejas, Anatomía, y clasificación de espinas.', 'Conocimiento de la piel, profundidad y precisión.',
                    'Indicaciones y contraindicaciones', 'Historía clínica', 'Manejo de equipo', 'Trazos básicos y avanzados microblading.', 'Práctica en piel sintética.'],
                ['¿Cómo realizar un retoque?', 'Bioseguridad', 'Colorimetría', 'Proveedores', 'Anestesia', 'Cuidados post tratamiento.', 'Protocolo completo para realizar el procedimiento.',
                    'Técnicas de Micropingmentación Powder Brows, híbrida y compacto.', 'Práctica en piel sintética.'],
                ['Práctica en modelo real en compañía de la máster.', 'Manejo de la fotografía y redes sociales.', 'Entrega de certificado.'],
            ],
            price: {
                group: '$4.000.000',
                personalized: '$5.000.000'
            }
        },
        {
            title: 'Master Class Micropigmentación',
            subtitle1: 'Técnica en Sombreados',
            subtitle2: "Presencial Grupal / Personalizado",
            photo: 'images/microblanding-6.JPG',
            photos: ['images/microblanding-6.JPG'],
            description: [""],
            techniques: [
                {
                    name: 'Powder Brows',
                    description: 'Efecto polvo, obteniendo menos intensidad en la zona de la cabeza y mayor potencia de color en el final de la ceja.'
                },
                {
                    name: 'Compacto',
                    description: 'Obteniendo mayor intensidad y cubrimiento'
                },
                {
                    name: 'Neutralización y corrección',
                    description: 'ideal para solucionar malos procedimientos en Micropigmentación y colores no deseados.'
                },
            ],
            content: ['Introducción en Micropigmentación', "Visagismo, diseño de cejas", "Conocimiento de la piel", "Profundidad y presición.", 
                "Indicaciones y contraindicaciones","Historía clínica", "Bioseguridad", "Anestesia", "Cuidados post tratamiento", 
                "Protocolo completo para realizar el procedimiento de cejas desde el inicio hasta el final", "Cómo realizar un retoque", "Manejo de equipos", 
                "Técnicas Powder Brows", "Técnica compacto", "Neutralización y correciones", "Práctica en piel sintética", "Práctica en modelo real en compañía del máster",
                "Colorimetría", "Manejo de la fotografía y redes sociales", "Proveedores"],
            kits: [
                ["1 Inductor", "10 Agujas microblading", "1 Dermógrago", "10 Agujas para dermógrafo", "1 Kit de 3 pigmentos", "2 Piel Sintética", 
                "1 Regla calibradora pie de Rey", "1 Regla compás","1 Lápiz de cera", "1 Anestesia en crema", "Cartilla de aprendizaje", "Bloc de notas", "Certificado",
                "Acompañamiento durante 6 meses vía WhatsApp"],
                ["Delantal", "Protector para el cabello",]
            ],
            days: [

                ['Introducción al microblading.', 'Visagismo', 'Diseño de cejas, Anatomía, y clasificación de espinas.', 'Conocimiento de la piel, profundidad y precisión.',
                    'Indicaciones y contraindicaciones', 'Historía clínica', 'Manejo de equipo', 'Trazos básicos y avanzados microblading.', 'Práctica en piel sintética.'],
                ['¿Cómo realizar un retoque?', 'Bioseguridad', 'Colorimetría', 'Proveedores', 'Anestesia', 'Cuidados post tratamiento.', 'Protocolo completo para realizar el procedimiento.',
                    'Técnicas de Micropingmentación Powder Brows, híbrida y compacto.', 'Práctica en piel sintética.'],
                ['Práctica en modelo real en compañía de la máster.', 'Manejo de la fotografía y redes sociales.', 'Entrega de certificado.'],
            ],
            price: {
                group: '$4.000.000',
                personalized: '$5.000.000'
            }
        },
        {
            title: 'Master Class Natural Line',
            subtitle1: 'Perfeccionamiento Trazos Hiperrealistas',
            subtitle2: "Presencial Personalizado",
            photo: 'images/curso-personalizado.JPEG',
            photos: ['images/curso-personalizado.JPEG', 'images/microblanding-5.JPG'],
            description: ["En este curso podrás adquirir habilidades mas avanzadas en estructuras y trazos con mas realismo y naturalidad que te llevaran a un nivel superior. Estamos convencidos de que la excelencia es la base fundamental para el éxito de un profesional del Microblading. Para participar en el máster debe tener conocimientos en microblading."],

            techniques: [
                {
                    name: 'Hiperrealismo',
                    description: '',
                },
                {
                    name: "Natural Line",
                    description: "Es una técnica con el fin de expresar naturalidad, realizando conexiones y trazos libres adaptadas a la necesidad sin transformar el movimiento del vello natural. Es la creación de unas cejas únicas a su máxima expresión, elegancia y naturalidad."
                }
            ],
            content: ['Remotamos diseño de cejas.', 'Anatomía de las cejas.', 'Clasificación de espinas.', 'Trazos hiperrealismo: Inicios y espinas.', 'Tipos de calibre de agujas.',
                'Protocolo completo para realizar el procedimiento.', 'Práctica en piel sintética.', 'Demostración en modelo real.', 'Certificado'],
            kits:
                [
                    ["1 Tebori", "10 Agujas", "1 Pigmento Profesional", "2 Piel Sintética", "1 Regla calibradora pie de Rey", "1 Regla compás",
                        "1 Lápiz de cera", "1 Anestesia en crema", "Cartilla de aprendizaje", "Bloc de notas", "Certificado"],
                    ["Delantal", "Protector para el cabello",]
                ],
            days: [
                ['Retomamos diseño de cejas.', 'Anatomía de las cejas.', 'Clasificación de espinas.', 'Trazos hiperrealismo: Inicios y espinas.', 'Tipos de calibre de agujas.',
                    'Protocolo completo para realizar el procedimiento.', 'Práctica en piel sintética.', 'Demostración en modelo real.', 'Certificado'],['']
            ],
            price: {
                group: '',
                personalized: '$2.000.000'
            }
        },
        {
            title: 'Master Class Micro Lips',
            subtitle1: 'Micropigmentación en labios',
            subtitle2: "Presencial Grupal / Personalizado",
            photo: 'images/microlips-2.JPG',
            photos: ['images/microlips-1.jpg', 'images/microlips-2.JPG'],
            description: ["La Micropigmentación en labios permite embellecer el rostro con las técnicas más novedosas que están actualmente. A través de la implantación de un tono adecuado al fototipo del color de labio y mejorando la simetría de ellos. Aprenderás para que necesidad están las técnicas"],
            techniques: [
                {
                    name: 'Hidra BB-Lips',
                    description: 'Esta técnica es maravillosa aporta hidratación y a su ves le damos unos destellos de color obteniendo la mejor combinación para lucir unos labios hermosos e hidratados.'
                },
                {
                    name: 'Neutralización',
                    description: ''
                },
                {
                    name: 'Revitalización',
                    description: ''
                },
                {
                    name: 'Full color',
                    description: ''
                },
            ],
            content: ['Conocimiento de la Micropigmentación.', 'Diseño de labios y sus partes.', 'Indicaciones y contraindicaciones.', 'Conocimiento de piel.', 'Profundidad de la piel.', 'Cuidados Pre-Pos Tratamientos.',
                'Historia clínica', 'Manejo de la anestesia.', 'Manejo del Dermógrafo y Dermapen', 'Manejo de cada técnica: Hidra BB Lips, neutralización, revitalización y full color.', 'Protocolo completo al procedimiento.',
                'Colorimetría', 'Bioseguridad', 'Manejo de fotografía.', 'Proveedores.', 'Práctica en piel sintética.', 'Práctica en modelo real en compañía de la Master.'],
            kits:
                [
                    ["1 Dermógrafo", "10 agujas para dermógrafo", "3 pigmentos", "1 vial de ácido hialuronico en hidratación.", "1 lápiz blanco", "1 piel sintética", "1 libro de la técnica.", "1 anestesia", 'Acompañamiento durante 6 meses vía WhatsApp.', 'Certificado.'],
                    ["Delantal", "Protector para el cabello",]
                ],
            days: [

                ['Introducción en la micropigmentación.', 'Diseño de labios y sus partes.', 'Conocimiento de piel.', 'Conocimiento de la piel, profundidad y precisión',
                    'Manejo de la anestesia.', 'Colorimetría. Proveedores', 'Manejo del Dermógrafo y Dermapen.', 'Técnicas Hidra BB-Lips, neutralización, revitalización y full color.',
                    'Práctica en piel sintética.'],
                ['Indicaciones y contraindicaciones.', 'Historía clínica', 'Cuidados pre-post tratamientos.', 'Protocolo completo al procedimiento.', 'Bioseguridad', 'Manejo de fotografía.',
                    'Práctica en modelo real en compañía de la máster.', 'Clausura', 'Certificación'],
            ],
            price: {
                group: '$4.200.000',
                personalized: '$5.200.000'
            }
        },
        // {
        //     title: 'Master Class VIP',
        //     subtitle1: 'Micropingmentación Cejas & Labios',
        //     subtitle2: "Presencial Grupal / Personalizado",
        //     photo: 'images/masterclassvip.png',
        //     photos: ['images/microblanding-1.jpeg', 'images/microlips-2.jpg'],
        //     description: "Este Master Class VIP reune nuestros cursos Master Class VIP en Cejas y Master Class Micro Lips.",
        //     techniques: [
        //         {
        //             name: 'Microblanding y micropigmentación',
        //             description: 'Pelo a pelo, Powder Brows, Híbrida.'
        //         },
        //         {
        //             name: 'Microlips',
        //             description: 'Hidra BB-Lips con Hialurónico, Neutralización, Revitalización, Full color.'
        //         },
        //     ],
        //     content: ['El contenido de este curso reune el contenido de los cursos de Master Class VIP en Cejas y Master Class Micro Lips'],
        //     kits:
        //         [
        //             ["1 dermógrado + 20 agujas", "1 inductor", "10 agujas microblanding.", '3 pigmentos profesionales para cejas.', '3 pigmentos para labios.', '1 vial de ácido hialurónico en hidratación.',
        //                 '4 pieles sintéticas.', '1 regla pie de rey.', '1 regla compás.', '1 lápiz de cera para cejas.', '1 lápiz para labios.', '1 anestesia en crema.', '1 cartilla de aprendizaje de cejas y labios.',
        //                 '1 block de notas.', 'Certificado', 'Acompañamiento durante 6 meses vía Whatsapp.'],
        //             ["Delantal", "Protector para el cabello",]
        //         ],
        //     days: [

        //         ['Introducción al microblanding', 'Visagismo', 'Diseño de cejas, anatomía, y clasificación de espinas', 'Conocimiento de la piel, profundidad y precisión',
        //             'Indicaciones y contraindicaciones historía clínica', 'Manejo del inductor, trazos pelo a pelo en técnicas básicas y avanzadas', 'Prácticas en piel sintéticas'],
        //         ['Introducciónsaaa   al microblanding', 'Visagismo', 'Diseño de cejas, anatomía, y clasificación de espinas', 'Conocimiento de la piel, profundidad y precisión',
        //             'Indicaciones y contraindicaciones historía clínica', 'Manejo del inductor, trazos pelo a pelo en técnicas básicas y avanzadas', 'Prácticas en piel sintéticas'],
        //         ['Introducciónsaaa   al microblanding', 'Visagismo', 'Diseño de cejas, anatomía, y clasificación de espinas', 'Conocimiento de la piel, profundidad y precisión',
        //             'Indicaciones y contraindicaciones historía clínica', 'Manejo del inductor, trazos pelo a pelo en técnicas básicas y avanzadas', 'Prácticas en piel sintéticas'],
        //         ['Introducciónsaaa   al microblanding', 'Visagismo', 'Diseño de cejas, anatomía, y clasificación de espinas', 'Conocimiento de la piel, profundidad y precisión',
        //             'Indicaciones y contraindicaciones historía clínica', 'Manejo del inductor, trazos pelo a pelo en técnicas básicas y avanzadas', 'Prácticas en piel sintéticas'],
        //     ],
        //     price: {
        //         group: '$4.200.000',
        //         personalized: '$5.200.000'
        //     }
        // },
    ]

export { courseContent };